<template>
  <article class="section">
    <div class="container">
      <section class="columns block">
        <aside class="column is-one-fifth-tablet is-2-fullhd">
          <b-menu>
            <b-menu-list label="Accounts">
              <b-menu-item :href="CONFIG_KEYCLOAK_ADMIN_URL" tag="a" target="_blank">
                <template #label>
                  Keycloak admin
                  <span class="icon is-small has-text-grey-light is-pulled-right" title="Externe link">
                    <i aria-hidden="true" class="gg-external"></i>
                    <span class="is-sr-only">Externe link</span>
                  </span>
                </template>
              </b-menu-item>
              <b-menu-item label="Organisaties" tag="router-link" :to="{ name: 'organisation-list' }"></b-menu-item>
            </b-menu-list>
            <b-menu-list label="Facturatie">
              <b-menu-item label="Facturen" tag="router-link" :to="{ name: 'invoice-list' }"></b-menu-item>
              <b-menu-item label="Prijzen" tag="router-link" :to="{ name: 'price-list' }"></b-menu-item>
            </b-menu-list>
            <b-menu-list label="Data">
              <b-menu-item label="Data explorer" tag="router-link" :to="{ name: 'explorer' }"></b-menu-item>
              <b-menu-item label="GDPR" tag="router-link" :to="{ name: 'gdpr-list' }"></b-menu-item>
              <b-menu-item label="Blacklist" tag="router-link" :to="{ name: 'blacklist' }"></b-menu-item>
            </b-menu-list>
            <b-menu-list label="Tickets">
              <b-menu-item label="Ticket Pagina" tag="router-link" :to="{ name: 'ticket' }"></b-menu-item>
            </b-menu-list>
            <b-menu-list label="Utils">
              <b-menu-item label="Label Dump" tag="router-link" :to="{ name: 'label-dump' }"></b-menu-item>
              <b-menu-item label="Log View" tag="router-link" :to="{ name: 'log-view' }"></b-menu-item>
            </b-menu-list>
          </b-menu>
        </aside>
        <main class="column">
          <header class="block">
            <h1 class="title">Innosend Admin</h1>
          </header>
          <subscription-changes-list></subscription-changes-list>
          <recent-organisation-list></recent-organisation-list>
        </main>
      </section>
    </div>
  </article>
</template>

<script>
  import SubscriptionChangesList from '@/components/SubscriptionChangesList';
  import RecentOrganisationList from '@/components/RecentOrganisationList';
  import { CONFIG_KEYCLOAK_ADMIN_URL } from '@/config';

  export default {
    computed: {
      CONFIG_KEYCLOAK_ADMIN_URL() {
        return CONFIG_KEYCLOAK_ADMIN_URL;
      },
    },
    components: { RecentOrganisationList, SubscriptionChangesList },
  };
</script>
