import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import NoAccess from '@/views/NoAccess.vue';
import { authGuard } from './auth_guard';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: authGuard,
  },
  {
    path: '/explorer',
    name: 'explorer',
    component: () => import(/* webpackChunkName: "explorer" */ '@/views/Explorer.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/gdpr',
    name: 'gdpr-list',
    component: () => import(/* webpackChunkName: "gdpr" */ '@/views/GdprList.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/invoice',
    name: 'invoice-list',
    component: () => import(/* webpackChunkName: "invoice" */ '@/views/InvoiceList.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/invoice/totals',
    name: 'invoice-totals',
    component: () => import(/* webpackChunkName: "invoice" */ '@/views/InvoiceTotals.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/invoice/accounttotals',
    name: 'account-totals',
    component: () => import(/* webpackChunkName: "invoice" */ '@/views/AccountTotals.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/invoice/create',
    name: 'invoice-create-list',
    component: () => import(/* webpackChunkName: "invoice" */ '@/views/InvoiceCreateList.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/invoice/create/:organisationId',
    name: 'invoice-form',
    component: () => import(/* webpackChunkName: "invoice" */ '@/views/InvoiceForm.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/invoice/:invoiceId?',
    name: 'invoice-single',
    component: () => import(/* webpackChunkName: "invoice" */ '@/views/InvoiceSingle.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/organisation',
    name: 'organisation-list',
    component: () => import(/* webpackChunkName: "organisation" */ '@/views/OrganisationList.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/organisation/:organisationId',
    name: 'organisation-single',
    component: () => import(/* webpackChunkName: "organisation" */ '@/views/OrganisationSingle.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/organisation/:organisationId/update',
    name: 'organisation-form',
    component: () => import(/* webpackChunkName: "organisation" */ '@/views/OrganisationForm.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/price',
    name: 'price-list',
    component: () => import(/* webpackChunkName: "price" */ '@/views/PriceList.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/price/create',
    name: 'price-form',
    component: () => import(/* webpackChunkName: "price" */ '@/views/PriceForm.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/price/:priceId?',
    name: 'price-single',
    component: () => import(/* webpackChunkName: "price" */ '@/views/PriceSingle.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/ticket/',
    name: 'ticket',
    component: () => import(/* webpackChunkName: "ticket" */ '@/views/TicketVersionTwo.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/label-dump/',
    name: 'label-dump',
    component: () => import(/* webpackChunkName: "labeldump" */ '@/views/LabelDump.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/log-view/',
    name: 'log-view',
    component: () => import(/* webpackChunkName: "log" */ '@/views/LogView.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/migrate',
    name: 'migrate',
    component: () => import(/* webpackChunkName: "migrate" */ '@/views/Migrate.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '*',
    name: 'no-access',
    component: NoAccess,
  },
  {
    path: '/blacklist',
    name: 'blacklist',
    component: () => import(/* webpackChunkName: "blacklist" */ '@/views/Blacklist.vue'),
    children: [
      {
        path: 'create',
        name: 'blacklist-create',
        component: () => import(/* webpackChunkName: "blacklist" */ '@/views/BlacklistForm.vue'),
      },
      {
        path: ':id',
        name: 'blacklist-update',
        component: () => import(/* webpackChunkName: "blacklist" */ '@/views/BlacklistForm.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

// Monkeypatch some helper functions for easier history navigation
router._previousRoute = null;

router.beforeEach((to, from, next) => {
  // Make sure we can navigate back
  router._previousRoute = from;
  next();
});

router.getPreviousRoute = function () {
  /**
   * Returns previous route, unless the page is freshly loaded (because of a
   * reload or explicit navigation)
   */
  if (VueRouter.START_LOCATION === this._previousRoute) return null;
  return this._previousRoute;
};

router.backOrDefault = function (route) {
  /**
   * Navigate back in history, unless that might lead to an external page. In
   * that case, push a to the default route.
   *
   * TODO: replace may be more semantically appropriate than push, which makes
   * more sense from the usability perspective?
   */
  if (this.getPreviousRoute()) this.go(-1);
  else this.push(route);
};

export default router;
